const zhCN = {
    logging: "登入中...",
    loginError: "登入失败，请重试",

    header: {
        about: "关于我们",
        introduction: "公司简介",
        partner: "合作伙伴",
        quote: "保险报价",
        claim: "装修保险",
        domestic: "家佣保险",
        home: "家居保险",
        travel: "旅游保险",
        owner: "置乐保",
        expressTravel: "特快旅游保",
        helperTravel: "特快家佣保",
        bziPartner: "商业伙伴",
        konwledge: "保险知识",
        contactUs: "联络我们",
        login: "登入",
        logout: "登出",
        hello: "您好！"
    },

    home: {
        title: "单车意外保险",
        desc: "单车意外保险保险您骑单车当天因意外而涉及的医疗费用及损失。",
        title2: "保障范围",
        title3: "(1)意外医疗费用",
        desc3: "保障受保人若不幸再保障日期当天骑单车时因意外导致受伤所需的医疗、手术及住院费用。受保的医疗项目包括中医、跌打及针灸。",
        desc4: "最高保障额：HK$5,000",
        desc5: "中医、跌打及针灸赔偿限额：",
        desc6: "-每天每次HK$150",
        desc7: "-保障上限合共HK$1,000",
        desc8: "垫底费",
        desc9: "投保人需为每次索偿承担HK$200垫底费",
        title4: "(2)意外死亡及永久完全或部分伤残",
        desc10: "保障受保人若不幸在保障日期当天骑单车时因意外导致死亡或伤残。",
        desc11: "最高保障额：HK$400,000",
        title5: "(3)个人责任",
        desc12: "保障受保人在保障日期当天骑单车时疏忽意外致第三者身体受伤或财物损毁而需承担的法律责任。",
        desc13: "(涉及与任何机械驱动车辆产生之责任除外)",
        title6: "最高保障额：HK$200,000",
        desc14: "有关详细资料及不保事项，请参阅",
        product: "产品单张",
        and: "及",
        trem: "保单条款",
        desc15: "上述资料只供参考之用，并不构成保险合约的一部分。此项保障计划的内容细则及不承保事项详列于保单内，如有任何差异，均以保单之条款细则为准。 ",
        select: "选择",
        plan: "计划",
        planSpec1: "意外医疗费用",
        max: "(最高保障)",
        planSpec2: "意外死亡及永久完全或部分伤残",
        planSpec3: "个人责任",
        money: "保费",
        moneySpec: "(已包含保费征费)",
        planA: "计划A",
        planB: "计划B",
        date: "保障日期",
        referer: "由以下平台转介",
        submit: "立即投保",
        errorDate: "请选择保障日期"
    },

    step: {
        title: "立即申请",
        step1: "填写个人资料",
        step2: "确认及付款",
        step3: "完成"
    },

    info: {
        title: "单车意外保险",
        setter: "投保人资料",
        setterName: "投保人姓名",
        hkId: "香港身份证号码",
        hkIdSpec: "须与香港身份证相同，例：A123456(7)，请输入 A1234567。",
        birDate: "出生日期",
        mobile: "电话号码（手提）",
        email: "电邮地址",
        getter: "受保人资料",
        copy: "从投保人资料复制",
        getterName: "受保人姓名",
        detail: "保障内容",
        date: "保障日期",
        plan: "保障计划",
        money: "保费：",
        moneySpec: "（已包含保费征费）",
        trem: "条款及细则",
        agree_1: "本人/我们确认已阅读并理解贵公司的",
        agree_2: "条款和条件",
        agree_3: "声明。",
        agree_4: "收集个人资料",
        state: "私隐政策",
        desc1: "单车意外保险由世联保险有限公司(Allied World Assurance Company, Ltd)承保。",
        desc2: "投保服务由安邦保险经纪有限公司(LPC Insurance Brokers Limited)提供。",
        next: "下一步",
        onlyEn: "只提供英文版本",
        stateShow: "收集个人资料声明",
        planSpec: "计划",

        verify: {
            setter: {
                name: "请输入投保人姓名",
                idNo: "请输入正确的香港身份证号码",
                birDate: "投保人必须大于18周岁",
                phone: "请输入正确的电话号码",
                email: "请输入正确的电邮地址"
            },
            getter: {
                name: "请输入受保人姓名",
                idNo: "请输入正确的香港身份证号码",
                birDate: "受保人必须在0-70周岁之间",
            },
            date: "请选择保障日期"
        },

        agree1: "请先确认已阅读并理解条款和条件声明。",
        agree2: "请先确认已阅读并理解收集个人资料声明。"
    },

    detail: {
        paymentMethod: "选择付款方式",
        prev: "上一页",
        confirm: "确认及付款"
    }
};

export default zhCN;