<template>
  <header id="header">
    <div class="btn-menu" @click="showMenu">☰</div>

    <router-link to="/">
      <img src="../../assets/images/logo.png" alt="LOGO" class="logo" />
    </router-link>

    <ul class="menu" :class="menuClass">
      <div class="btn-menu-close" @click="hideMenu"></div>

      <li>
        <a :href="url('about')">{{ $t("header.about") }}</a>
        <a @click="aboutDropdown = !aboutDropdown">&nbsp;▼</a>
        <ul class="dropdown" v-show="aboutDropdown">
          <li>
            <a :href="url('about/introduction')">{{
              $t("header.introduction")
            }}</a>
          </li>
          <li>
            <a :href="url('about/partner')">{{ $t("header.partner") }}</a>
          </li>
        </ul>
      </li>
      <li>
        <a :href="url('claim/quote')">{{ $t("header.quote") }}</a>
        <a @click="listDropdown = !listDropdown">&nbsp;▼</a>
        <ul class="dropdown" v-show="listDropdown">
          <li>
            <a :href="url('claim/quote')">{{ $t("header.claim") }}</a>
          </li>
          <li>
            <a
              :href="url('claim/quote?product_type=domestic_helper_product')"
              >{{ $t("header.domestic") }}</a
            >
          </li>
          <li>
            <a :href="url('claim/quote?product_type=home_product')">{{
              $t("header.home")
            }}</a>
          </li>
          <li>
            <a :href="url('claim/quote?product_type=travel_product')">{{
              $t("header.travel")
            }}</a>
          </li>
          <li>
            <a :href="url('claim/quote?product_type=new_owner_product')">{{
              $t("header.owner")
            }}</a>
          </li>
          <li>
            <a :href="url('claim/quote?product_type=travel_express_product')">{{
              $t("header.expressTravel")
            }}</a>
          </li>
          <li>
            <a :href="url('claim/quote?product_type=helper_express_product')">{{
              $t("header.helperTravel")
            }}</a>
          </li>
        </ul>
      </li>
      <li>
        <a :href="url('partner')">{{ $t("header.bziPartner") }}</a>
      </li>
      <li>
        <a :href="url('konwledge')">{{ $t("header.konwledge") }}</a>
      </li>
      <li>
        <a :href="url('contact-us')">{{ $t("header.contactUs") }}</a>
      </li>
      <li v-if="!isLogin">
        <a @click="login">{{ $t("header.login") }}</a>
      </li>
      <li v-if="isLogin">{{ $t("header.hello") }}{{ username }}</li>
      <li v-if="isLogin">
        <a @click="logout">{{ $t("header.logout") }}</a>
      </li>
      <li class="lang">
        <a @click="selectLang('zhHK')">繁</a> /
        <a @click="selectLang('zhCN')">简</a> /
        <a @click="selectLang('en')">English</a>
      </li>
    </ul>
  </header>
</template>
<script>
import CONFIG from "../../config";
import { mapState } from "vuex";

const baseUrl = "https://insurfun.com/";

export default {
  name: "Header",

  computed: {
    ...mapState({
      isLogin: (state) => state.user.login,
      username: (state) => state.user.username,
      userInfo: (state) => state.user.info,
    }),
  },

  data() {
    return {
      menuClass: "animate__slideInLeft",
      aboutDropdown: false,
      listDropdown: false,
    };
  },

  methods: {
    url(path) {
      return baseUrl + "tc/" + path;
    },

    selectLang(lang) {
      localStorage.setItem("lang", lang);
      location.reload();
    },

    showMenu() {
      this.menuClass = "animate__animated animate__slideInLeft show";
    },

    hideMenu() {
      const self = this;

      self.menuClass = "animate__animated animate__slideOutLeft show";

      setTimeout(() => {
        self.menuClass = "animate__animated animate__slideInLeft";
      }, 1000);
    },

    login() {
      let url =
        CONFIG.oauth2 +
        "?client_id=" +
        CONFIG.clientId +
        "&scope=basic email profile openid&state=STATE&redirect_uri=" +
        CONFIG.domain +
        "/login&response_type=code";

      document.location.href = url;
    },

    logout() {
      window.localStorage.removeItem("token");
      document.location.reload();
    },
  },
};
</script>
<style scoped lang="less">
#header {
  padding: 0 16px;
  width: 100%;
  height: 66px;
  line-height: 66px;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 100;

  .logo {
    margin: 10px 0;
    height: 46px;
    position: absolute;
  }

  .menu {
    margin: 0;
    font-weight: 500;
    text-align: right;

    > li {
      padding: 0 15px;
      list-style: none;
      display: inline-block;
      position: relative;
    }

    a {
      color: #333;
      text-decoration: none;
      cursor: pointer;
    }

    .lang {
      padding-left: 30px;

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .dropdown {
    margin: 0;
    padding: 15px;
    width: 160px;
    position: absolute;
    list-style: none;
    top: 45px;
    left: 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background: #fff;

    li {
      &::before {
        content: "◾";
        padding-right: 8px;
      }

      margin: 2px 0;
      line-height: 26px;
      text-align: left;
      font-size: 16px;
    }
  }

  .btn-menu {
    margin-right: 20px;
    color: #848484;
    font-size: 28px;
    display: none;
  }

  .btn-menu-close {
    display: none;
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.7;

    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: " ";
      height: 33px;
      width: 2px;
      background-color: #fff;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  @media (max-width: 1000px) {
    .menu {
      display: none;
      padding: 0;
      padding-top: 105px;
      width: 250px;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      background: #fcb92f;
      color: #fff;
      font-size: 17px;
      line-height: 20px;

      > li {
        display: block;
        text-align: left;
        margin-bottom: 45px;
      }

      a {
        color: #fff;
      }

      &.show {
        display: block;
      }

      .dropdown {
        margin: 10px 0;
        padding: 0;
        width: auto;
        position: relative;
        top: 0;
        left: 0;
        border-width: 0 0 0 1px;
        border-color: #fff;
        border-radius: 0;
        background: transparent;

        li {
          &::before {
            display: none;
          }

          margin: 2px 0;
          padding-left: 16px;
          text-align: left;
        }
      }

      .lang {
        width: 100%;
        background: #007553;
        position: absolute;
        bottom: -45px;
        padding: 30px 15px;

        a:hover {
          text-decoration: none;
        }
      }
    }

    .btn-menu {
      display: inline-block;
    }

    .btn-menu-close {
      display: block;
    }
  }
}
</style>