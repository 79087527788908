import { createApp } from "vue";
import App from "./App.vue";
import VueRouter from "./router.js";
import store from "./store";
import i18n from "./lang/i18n";

import "animate.css";
import "./assets/style/global.less";

import Fetch from './helper/fetch';

const app = createApp(App);
app.use(store);
app.use(VueRouter);
app.use(Fetch);
app.use(i18n);
app.mount("#app");