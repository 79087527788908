<template>
  <Step :step="3" />

  <div class="page container" id="Success">
    <div>
      <p>交易完成</p>
      <p>
        您的交易編碼：
        <span
          >{{ order.payment.type == "alipay" ? "AlipayHK-" : ""
          }}{{ order.payment.trade_no }}</span
        >
      </p>
      <p>收據已發送到 {{ info.setter.email }}. 請查收，謝謝。</p>
      <p><a href="https://www.insurfun.com/" class="link">返回主頁</a></p>
    </div>
  </div>
</template>
<script>
import Step from "../../components/Step";
import { mapState } from "vuex";
import util from "../../helper/util";

export default {
  name: "Success",

  components: {
    Step,
  },

  computed: {
    ...mapState({
      detail: (state) => state.detail.data,
    }),
  },

  mounted() {
    this.init();
  },

  data() {
    return {
      id: null,
      order: {
        payment: {
          trade_no: null,
        },
      },
      info: {
        setter: {},
        getter: {},
      },
      dateFormat: util.dateFormat,
    };
  },

  methods: {
    async init() {
      this.info = Object.assign({}, this.detail);
      this.id = this.$route.params.id;

      const result = await this.$fetch(`order/${this.id}`);
      if (!result.isOk) {
        return;
      }

      const order = result.data.data.order;

      this.order = order;

      this.info = Object.assign(
        {},
        {
          setter: {
            idNo: order.setter_id_no,
            birDate: order.setter_bir_date,
            name: order.setter_name,
            phone: order.setter_phone,
            email: order.setter_email,
          },
          getter: {
            idNo: order.getter_id_no,
            birDate: order.getter_bir_date,
            name: order.getter_name,
          },
          plan: order.plan,
          date: order.date,
        }
      );
    },
  },
};
</script>
<style scoped lang="less">
#Success {
  .link {
    display: inline-block;
    width: 94px;
    height: 34px;
    border-radius: 5px;
    background: #848484;
    text-align: center;
    line-height: 36px;
    color: #fff;
    font-size: 17px;
    text-decoration: none;
    outline: none;
  }
}
</style>