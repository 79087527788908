import { createStore } from 'vuex';

import detail from './detail.model';
import user from './user.model';

const store = createStore({
    modules: {
        detail,
        user
    }
});

export default store;