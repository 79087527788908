<template>
  <Step :step="1" />
  <div class="info container page">
    <div class="box">
      <div class="page-title">{{ $t("info.title") }}</div>
    </div>

    <div class="box">
      <div class="form-group">
        <div class="form-group-title">{{ $t("info.setter") }}</div>

        <div class="form-item" :class="{ error: error.setterName }">
          <label class="form-field">
            {{ $t("info.setterName") }}
            <span v-if="error.setterName">{{ error.setterName }}</span>
          </label>
          <input
            class="form-input"
            ref="setterName"
            v-model="setter.name"
            @blur="check('setterName')"
          />
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.setterId }">
            <label class="form-field">
              {{ $t("info.hkId") }}
              <span v-if="error.setterId">{{ error.setterId }}</span>
            </label>
            <input
              class="form-input"
              ref="setterId"
              v-model="setter.idNo"
              @blur="check('setterId')"
            />
            <p class="form-spec">
              {{ $t("info.hkIdSpec") }}
            </p>
          </div>

          <div class="form-item" :class="{ error: error.setterBirDate }">
            <label class="form-field">
              {{ $t("info.birDate") }}
              <span v-if="error.setterBirDate">{{ error.setterBirDate }}</span>
            </label>
            <flatPickr
              :config="Object.assign({ maxDate: 'today' }, config)"
              class="form-input"
              ref="setterBirDate"
              @on-change="check('setterBirDate')"
              v-model="setter.birDate"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.setterPhone }">
            <label class="form-field">
              {{ $t("info.mobile") }}
              <span v-if="error.setterPhone">{{ error.setterPhone }}</span>
            </label>
            <input
              class="form-input"
              v-model="setter.phone"
              ref="setterPhone"
              @blur="check('setterPhone')"
            />
          </div>

          <div class="form-item" :class="{ error: error.setterEmail }">
            <label class="form-field">
              {{ $t("info.email") }}
              <span v-if="error.setterEmail">{{ error.setterEmail }}</span>
            </label>
            <input
              class="form-input"
              v-model="setter.email"
              ref="setterEmail"
              @blur="check('setterEmail')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="form-group">
        <div class="form-group-title">{{ $t("info.getter") }}</div>

        <div class="form-item">
          <button class="btn-copy" @click="copy">{{ $t("info.copy") }}</button>
        </div>

        <div class="form-item" :class="{ error: error.getterName }">
          <label class="form-field">
            {{ $t("info.getterName") }}
            <span v-if="error.getterName">{{ error.getterName }}</span>
          </label>
          <input
            class="form-input"
            v-model="getter.name"
            ref="getterName"
            @blur="check('getterName')"
          />
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.getterId }">
            <label class="form-field">
              {{ $t("info.hkId") }}
              <span v-if="error.getterId">{{ error.getterId }}</span>
            </label>
            <input
              class="form-input"
              v-model="getter.idNo"
              ref="getterId"
              @blur="check('getterId')"
            />
          </div>

          <div class="form-item" :class="{ error: error.getterBirDate }">
            <label class="form-field">
              {{ $t("info.birDate") }}
              <span v-if="error.getterBirDate">{{ error.getterBirDate }}</span>
            </label>
            <flatPickr
              :config="config"
              class="form-input"
              ref="getterBirDate"
              @on-change="check('getterBirDate')"
              v-model="getter.birDate"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="form-group">
        <div class="form-group-title">{{ $t("info.detail") }}</div>

        <div class="form-item" :class="{ error: error.date }">
          <label class="form-field">
            {{ $t("info.date") }}
            <span v-if="error.date">{{ error.date }}</span>
          </label>

          <flatPickr
            class="form-input"
            :config="config"
            v-model="date"
            ref="date"
            @on-change="check('date')"
          />
        </div>

        <div class="form-item">
          <label class="form-field">{{ $t("info.plan") }}</label>
          <input v-model="plan" class="form-input" readonly="readonly" />
        </div>
      </div>
    </div>

    <div class="box">
      <div class="result">
        {{ $t("info.money")
        }}<span class="money">HK${{ [15, 28][detail.plan] }}</span>
        <span class="tips">{{ $t("info.moneySpec") }}</span>
      </div>
    </div>

    <div class="box">
      <div class="form-group">
        <div class="form-group-title">{{ $t("info.trem") }}</div>
        <div>
          <input
            type="checkbox"
            name="agree1"
            id="agree1"
            v-model="agree1"
            value="1"
          />
          <label for="agree1">
            {{ $t("info.agree_1")
            }}<a href="javascript:void(0)" @click="showTerms">{{
              $t("info.agree_2")
            }}</a
            >{{ $t("info.agree_3") }}
          </label>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="form-group">
        <div class="form-group-title">{{ $t("info.state") }}</div>
        <div>
          <input
            type="checkbox"
            name="agree2"
            id="agree2"
            v-model="agree2"
            value="2"
          />
          <label for="agree2">
            {{ $t("info.agree_1")
            }}<a href="javascript:void(0)" @click="showState">{{
              $t("info.agree_4")
            }}</a
            >{{ $t("info.agree_3") }}
          </label>
        </div>
      </div>
    </div>

    <div class="box">
      <div class="info-desc">
        <div>{{ $t("info.desc1") }}</div>
        <div>{{ $t("info.desc2") }}</div>
      </div>
    </div>

    <div class="form-action">
      <button class="btn" @click="next">{{ $t("info.next") }}</button>
    </div>

    <Modal v-model:visible="visibleTerms" :title="$t('info.trem')">
      <p>{{ $t("info.onlyEn") }}</p>

      <p>
        InsurFun.com (“'Website”) is owned and operated by LPC Insurance Brokers
        Limited (hereafter called “LPC”).<br />
        In the terms and conditions together with privacy &amp; cookies policies
        stated herein (collectively called “Terms and Conditions”), whenever we
        use "LPC", "us", "we" and "our", it refers to LPC Insurance Brokers
        Limited, a registered member of Professional Insurance Brokers
        Association, membership number is 0345. "You", "Your" and “User” refers
        to any person who accesses to or uses this Website.&nbsp;<br />
        <br />
        <strong>General&nbsp;</strong><br />
        <br />
        By using this Website, you agree to be bound by these Terms and
        Conditions of use without limitation. If you do not intend to be bound
        by these Terms and Conditions, you should not access to and use this
        Website.&nbsp;<br />
        <br />
        We may review and make changes to these Terms and Conditions from time
        to time without any prior notice. Any amended Terms and Conditions will
        become effective from the date they are posted on this Website. You
        shall review these Terms and Conditions whenever you visit this Website.
        If you do not intend to be bound by the revised Terms and Conditions,
        you should stop using this Website immediately. By accessing to and
        using of this Website, you shall deem to unconditionally accept the
        latest amended Terms and Conditions.&nbsp;<br />
        <br />
        <strong>Our Service&nbsp;</strong><br />
        <br />
        You acknowledge that LPC is an insurance broker whose business is to
        arrange insurance products and services for its clients. LPC is not an
        insurer, and does not underwrite any insurance risk, nor does it act on
        behalf of any insurer in its dealings with the User.&nbsp;<br />
        <br />
        LPC provides a free comparison service on this Website which allows you
        to compare a number of prices for products and services and their
        product information from the service providers on our panel. Any
        information gathered by you or use of any tools on this Website do not
        constitute an official offer of insurance to you.&nbsp;<br />
        <br />
        LPC does not provide any kind of advice on this Website, whether
        personal, financial or commercial. Nothing on this Website ought to be
        relied upon as advice or a recommendation of any specified products or
        services. It is your sole discretion and decision to purchase any
        product or service, whether it has been featured on this Website or
        not.&nbsp;<br />
        <br />
        The information on this Website is not sensitive to your specific status
        including financial or insurance needs and you are obligated to seek
        professional advice before acting on the information you find on this
        Website. The Information on this Website to be found in relation to the
        provided products and services are not representative of and cannot be
        treated as full description of any particular product and its terms and
        conditions nor the precise cover provided by that product or
        service.&nbsp;<br />
        <br />
        By giving us your personal details, you are requesting a quote of the
        relevant product or service from the relevant insurance providers based
        on its terms, conditions and exceptions.&nbsp;<br />
        You agree that no insurance coverage shall be construed as bound, in
        force, applied for or assumed as a result of providing the personal
        information or application form to LPC unless and until the risk was
        accepted and relevant confirmation of coverage has been sent to you by
        us or by respective insurers. Confirmation of insurance may be sent
        physically on paper &amp;/or solely electronically. The confirmation of
        a binding contract is not conditional on you receiving any paper
        document.&nbsp;<br />
        <br />
        LPC’s affiliation with any third party service providers or their
        content on this Website, if any, should not be construed as a
        recommendation by LPC to buy any of their insurance product or service.
        LPC does not own, operate, control or endorse any third party content or
        services in any way.&nbsp;<br />
        <br />
        We reserve the right to feature, amend, delete, edit, take away or
        modify any data, content, material, information or quotation engine
        displayed on this Website at any time.&nbsp;<br />
        <br />
        <strong>LPC Remuneration&nbsp;</strong><br />
        <br />
        LPC is remunerated for its services by the receipt of commission paid by
        insurers. Your agreement to proceed with any insurance transaction shall
        constitute your consent to the receipt of commission by us.&nbsp;<br />
        <br />
        <strong>Your Obligations&nbsp;</strong><br />
        <br />
        You acknowledge that any contract entered for an insurance product will
        be between you and the applicable insurer. All products and services
        provided or described in this Website or arranged by LPC are subject to
        the terms, conditions and exceptions of the insurance contract. You must
        read carefully all these terms, conditions and exceptions and to satisfy
        yourself that you fully understand such policy provisions. In
        appropriate cases, ensure to seek professional advice on the meaning,
        operation and substantive effect of these terms, conditions and
        exceptions prior to entering a contract for such products or services.
        If you are in doubt as to your obligations under these policy provisions
        or if you wish to seek help pertaining to our service, please contact
        our customer service department.&nbsp;<br />
        <br />
        You also acknowledge that prior to any insurance cover being confirmed
        or provided to you, you should disclose complete and accurate material
        facts you know, or could reasonably be expected to know that could be
        crucial in the process or the decision to grant you the requested
        insurance and in what premium terms that prudent insurers would offer to
        accept the risk. A fact is material and requires disclosure to the
        insurer even if not asked or prompted by the insurer in the proposal
        form or any other communication between you and the insurer. You agree
        that no reliance will be placed upon LPC to advise or alert the User as
        to what facts is material for disclosure.&nbsp;<br />
        <br />
        You are obligated (i) to disclose to your insurer immediately if any
        information is affected by any change of circumstances or additional
        facts known to you at anytime whereby the risk of loss is increased (ii)
        not to misrepresent any material facts and (iii) not to make fraudulent
        claims, if it is relevant to the nature of the risk sought to be
        covered. Please also ensure to comply with the terms, conditions and
        warranties stipulated in policy during the course of the whole insurance
        period. Failure to do so may prejudice your interest in seeking
        indemnity under your selected policy &amp;/or claims being repudiated
        &amp;/or policy being voided from inception. You may receive no benefits
        from your chosen policy accordingly.&nbsp;<br />
        <br />
        When obtaining quotation &amp;/or enrolling for a particular product or
        service on or through this Website, you agree that it is your sole
        responsibility to ensure all information or personal data provided are
        correct, accurate and complete. LPC will not be held responsible for
        loss of any kind by User due to any misrepresenting data, information
        provided or errors or omissions committed by the User. Please ensure to
        double check the information provided to us &amp;/or insurers to protect
        your own interests. Failure to do so may result in insurance contract
        being voided from inception as if no insurance were arranged or claims
        being repudiated.&nbsp;<br />
        <br />
        You agree that no premium will be refunded once a travel insurance
        certificate has been issued.&nbsp;<br />
        <br />
        The products and services described on this Website are not available to
        persons who are outside Hong Kong Special Administrative Region &amp;/or
        non-Hong Kong residents &amp;/or to persons who do not possess legal
        capacity to enter into contracts, such as minors. No contract purported
        to be entered by these persons will bind respective insurers or LPC,
        unless otherwise specified.&nbsp;<br />
        <br />
        <strong>Use of this Website&nbsp;</strong><br />
        <br />
        By accessing to and using of this website, you agree that:<br />
        You accept the risks of using this Website and/or any linked third party
        websites. You are solely responsible to evaluate and assume all the
        risks in connection with the use of any content or services on this
        Website and/or any linked third party websites including but not limited
        to their reliability, accuracy, completeness or usefulness of any
        content or services on or acquired through this Website or any linked
        third party websites.&nbsp;<br />
        <br />
        The terms and conditions found on third party websites may not align
        with the Terms and Conditions for use of this Website. LPC is not
        responsible for the use of linked third party websites. By activating a
        third party website link through this Website, you agree to solely
        responsible for any loss or damage suffered through the use of those
        websites.&nbsp;<br />
        You agree to comply with all applicable laws in Hong Kong Special
        Administrative Region and any other relevant jurisdiction pertaining to
        the use of or access to this Website and/or the obtaining of products or
        services through such use or access.&nbsp;<br />
        <br />
        You will not invade, extract, download, reproduce and/or display or
        advertise on any other website or other online service, any information
        obtained on this Website including, without limitation, any personal
        data, business or information of other Users, whether that information
        has been gained by the use of automatic computer code, process, program,
        robot, spiders, data mining, trawling or other 'screen scraping'
        software or system used to extract data. You also will not disrupt,
        harm, damage, alter, interfere or impose any unreasonable burden with
        this Website and/or linked third party websites and to cause
        inconvenience to other users of this Website.&nbsp;<br />
        <br />
        In the event that you are acting on behalf of any other persons to use
        this Website, all these Terms and Conditions is applicable both to the
        User who uses this Website, and to the person whom the User represents.
        The User who is actually using or accessing this Website warrants that
        he/she is duly authorized to do so by the person whom
        he&nbsp;represents. By giving us personal data &amp;/or information
        regarding another person you are confirming that you have asked the
        person who you are representing, to read carefully all these Terms and
        Conditions and have obtained consent to provide us such
        information.&nbsp;<br />
        <br />
        LPC cannot guarantee uninterrupted availability of this Website. You
        agree that LPC has the sole discretion and right to decline, suspend or
        terminate your access to &amp;/or use any of the content or services of
        this Website at anytime without any prior notice or
        explanation.&nbsp;<br />
        <br />
        You agree to be held fully responsible for any harm, loss, claim,
        demand, liability or expenses (including legal cost) that we suffer
        directly or indirectly as a result of your breach of contract or
        warranty, willful act or omission, misrepresentation, negligence in
        relation to, or in association with, your access and/or use on this
        Website.&nbsp;<br />
        <br />
        <strong>Complaint Procedure&nbsp;</strong><br />
        <br />
        If you wish to lodge a complaint about our services, you may contact our
        customer service department on 2375 3456 or write to us:&nbsp;<br />
        <br />
        LPC Insurance Brokers Ltd.<br />
        Rm 912, 9/F, Austin Tower, 22-26A Austin Avenue, Tsim Sha Tsui,
        Kowloon&nbsp;<br />
        <br />
        Please note that we cannot act on behalf of any third party product or
        service providers to answer complaints regarding their products or
        services.&nbsp;<br />
        <strong
          >Any access to or use of this Website is an acknowledgment that you
          fully comprehend and accept the Terms and Conditions of this
          Website.&nbsp;</strong
        >
      </p>
    </Modal>

    <Modal v-model:visible="visibleState" :title="$t('info.stateShow')">
      <p>{{ $t("info.onlyEn") }}</p>

      <p>
        Purpose of Data Collection<br />
        <br />
        LPC or third party products providers may require your personal
        information for the following purposes: (a) providing price comparison
        of products or service on this Website (b) processing your application;
        (c) administration and process of insurance policies, insurance claims
        and underwriting purposes; (d) processing your payment; (e)
        communication to and notification for you; (f) providing you promotional
        information related to insurance or financial services or related wealth
        management products ; (g) identity verification and records ; (h)
        compiling Website statistics on usage and flow (i) meeting disclosure
        requirements imposed and permitted by local Law or regulatory
        authorities.<br />
        It is solely on a voluntary basis for you to provide such personal data
        or information on this Website or third party websites. If you do not
        wish to provide such information, we &amp;/or third party products
        providers will not be able to deliver or furnish your requested
        information, products or services.&nbsp;<br />
        <br />
        Transfer of Personal Data<br />
        <br />
        All personal data held will be kept confidential but may be transferred
        or disclosed to the following: (a) relevant insurers; (b) insurance
        broker through whom the insurance product is to be or intended to be
        arranged; (c) third party administrators; (d) third party service
        providers including legal advisors, investigators, loss adjusters,
        surveyors, specialists, repairers, accountants and data processors; (e)
        person(s) to whom LPC is obligated to disclose information under local
        requirements of any law binding LPC for the purpose of any regulations,
        codes or guidelines with which LPC is expected to comply with; (f)
        person(s) in accordance with a law or order of a court
        jurisdiction.&nbsp;<br />
        <br />
        Direct Marketing<br />
        <br />
        The personal data collected by us may be used in the marketing and
        promotion of other insurance services and products and must be within
        your consent. This data includes your address, phone number, email and
        name, and may be transferred to companies affiliated to LPC. Please opt
        out the choice during application if you do not wish to receive any
        promotion or marketing communications.&nbsp;<br />
        <br />
        Cookie Policy<br />
        <br />
        We collect certain information through the use of “cookies,” which are
        small text files saved by your browser when you access this Website to
        help analyzing web traffic and/or usage and/or flow on this Website and
        to identify which pages are being used. Cookies do not give us access to
        your computer or any specific information about you. We may use
        third-party service providers for the purpose of support and these
        third-party service providers may place their own cookies on your
        browser.&nbsp;<br />
        By using this Website and providing information, you agree our privacy
        and cookies policy. LPC reserves the right to change, edit, add or
        remove any part of this policy at any time. You accept any changes on
        this privacy and cookies policy from time to time as may be required by
        LPC.&nbsp;<br />
        <br />
        Access and Correction of Personal Data<br />
        <br />
        Pursuant to the Personal Data (Privacy) Ordinance (“Ordinance”), you
        have the right to check against and/or amend any personal information
        provided to us. Please send request by letter to our Data Protection
        Officer at Rm 912, 9/F, Austin Tower, 22-26A Austin Avenue, Tsim Sha
        Tsui, Kowloon or by email ( cs@lpcinsbrokers.com ). The Ordinance
        provides us the right to charge a reasonable fee for the processing of
        any personal information access or correction request.&nbsp;<br />
        <br />
        <strong>Disclaimer&nbsp;</strong><br />
        <br />
        LPC disclaims any representation, warranty or responsibility, whether
        express or implied, pertaining to the performance or operation or
        service quality of this Website and/or any linked third party websites
        that the use of all these websites will be uninterrupted or error free,
        the accuracy, suitability, reliability or completeness of the content or
        services of this Website and/or any linked third party websites. We
        expressly disclaim any liability in respect of any loss or damages due
        to any acts, errors or omissions of this Website.&nbsp;<br />
        <br />
        You agree that by accessing to and/or using the data, information or
        materials from this Website and/or any linked third party websites, the
        User is entirely at his/her own risk and LPC does not guarantee any
        downloaded files or information or use of this Website and/or any linked
        third party websites will not cause harm or damage to property, loss of
        data, loss of use, delay in operation or transmission, interruption,
        computer viruses, infections or any other forms of malicious software,
        theft or corruption, destruction or unauthorized access to, alteration
        of or use of record of the User. LPC expressly disclaims any liability
        for any such damage or loss suffered by the User in connection with or
        arising out of the use of this Website and/or any linked third party
        websites; and/or any claim based in agreement, or otherwise, for loss of
        profits of any kind whether direct or indirect and/or any consequential
        loss.&nbsp;<br />
        <br />
        LPC expressly disclaims any liability in respect of any claim pertaining
        to the content or services provided made available by or through this
        Website and/or any linked third party websites or any interference,
        interruption, suspension or termination of this Website or any part
        thereof which is attributable to an event or circumstance beyond LPC's
        control including but not limited to acts of God, war, flood, fire,
        explosion, civil commotion, governmental action, legislation not in
        force at the time of these Terms and Conditions, restrictions imposed by
        governments or by any other regulatory authorities, labour disputes,
        trade disputes or delays of third parties over which LPC has no
        control.&nbsp;<br />
        You will be able to purchase or apply for a different products and
        services on or through this Website. The products and services are
        provided by third parties providers. You agree that you are solely
        responsible for any of your chosen products or services. LPC will not
        assume any liability whether directly or indirectly or to be blamed for
        any damages, loss or harm suffered resulting from any products or
        services obtained or purchased attributable to the use of or through
        this Website or for any acts, omissions, errors or defaults of any third
        party in relation to that product or service.&nbsp;<br />
        <br />
        You acknowledge that the access to and use any of the linked third party
        websites is solely at your own discretion and risk. Any links to third
        party websites are provided for your interest and convenience only. LPC
        disclaims any liability as a result of your activating the link to third
        party websites and suffer from any direct or indirect losses or damages
        or harm from any third party websites.&nbsp;<br />
        <br />
        You also acknowledge that the product or service descriptions featured
        or provided on this Website might not represent the whole descriptions
        of all the options and/or the terms and conditions of these products and
        services and may not be error free. You agree and understand that it is
        the User’s sole responsibility to fully comprehend all the options and
        the terms and conditions of any product or service before purchasing
        it.&nbsp;<br />
        Access to this Website may be suspended or interrupted at any time
        without prior warning or notice to you use in order to allow
        maintenance, repairs or other practical reasons. LPC holds no
        responsibility for direct or indirect losses or damages arising out of
        any interruption or incapability to access to or use of this Website
        including, without limitation, delay or loss of insurance protection,
        loss of profits or revenue, loss of business or business opportunity or
        any consequential loss for which you may suffer.&nbsp;<br />
        <br />
        <strong>Intellectual property&nbsp;</strong><br />
        <br />
        LPC is the owner or the licensee of all intellectual property rights and
        copyright on this Website and in the content, material and information
        published on it including, without limitation, all trademarks, logos,
        business names, trading names, design rights, database rights, copyright
        and any other intellectual property rights.&nbsp;<br />
        <br />
        You can print, copy, transfer or store extracts of content, material or
        information displayed on this Website for your own personal use only
        provided always that:&nbsp;<br />
        <br />
        •&nbsp;&nbsp; &nbsp;- &nbsp;You will not use it for any commercial
        purpose including distributing or selling it&nbsp;without our prior
        written consent.&nbsp;<br />
        <br />
        •&nbsp;&nbsp; &nbsp;- &nbsp;All copies must retain any copyright or
        other intellectual property notices contained&nbsp;within the original
        material.&nbsp;<br />
        <br />
        •&nbsp;&nbsp; &nbsp;- &nbsp;No logos or trademarks displayed on this
        Website may be printed or downloaded,&nbsp;unless it forms part of the
        whole document.&nbsp;<br />
        <br />
        •&nbsp;&nbsp; &nbsp;- &nbsp;You must not amend or edit the downloaded,
        extract or printed copy of the content,&nbsp;material or information
        from this Website, whether in paper or digital format, and you must not
        use any illustrations, photographs, video or audio sequences, logos,
        designs or any graphics separately from any accompanying
        context.&nbsp;<br />
        <br />
        •&nbsp;&nbsp; &nbsp;- &nbsp;You agree that all images displayed on this
        Website are protected by copyright and prior written approval from
        respective owners will be required before reproducing in any
        manner.&nbsp;<br />
        <br />
        •&nbsp;&nbsp; &nbsp;- &nbsp;You agree that LPC (and any known
        contributors) as the authors of the content, material or information on
        this Website must always be acknowledged.&nbsp;<br />
        <br />
        <strong>Complaint Procedure&nbsp;</strong><br />
        <br />
        If you wish to lodge a complaint about our services, you may contact our
        customer service department on 2375 3456 or write to us:&nbsp;<br />
        <br />
        LPC Insurance Brokers Ltd.<br />
        Rm 912, 9/F, Austin Tower, 22-26A Austin Avenue, Tsim Sha Tsui,
        Kowloon&nbsp;<br />
        <br />
        Please note that we cannot act on behalf of any third party product or
        service providers to answer complaints regarding their products or
        services.&nbsp;<br />
        <br />
        <strong
          >Any access to or use of this Website is an acknowledgment that you
          fully comprehend and accept the Terms and Conditions of this
          Website.&nbsp;</strong
        ><br />
        &nbsp;
      </p>
    </Modal>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { MandarinTraditional } from "flatpickr/dist/l10n/zh-tw";
import Step from "../../components/Step";
import { mapState } from "vuex";
import Modal from "../../components/Modal";
import util from "../../helper/util";

export default {
  name: "Info",

  components: {
    Step,
    flatPickr,
    Modal,
  },

  computed: {
    ...mapState({
      detail: (state) => state.detail.data,
    }),
  },

  data() {
    return {
      plan: "",

      config: {
        disable: ["1900-01-01"],
        wrap: true,
        altInput: true,
        altFormat: "d/m/Y",
        locale: MandarinTraditional,
      },

      error: {
        setterName: false,
        setterId: false,
        setterBirDate: false,
        setterPhone: false,
        setterEmail: false,
        getterName: false,
        getterId: false,
        getterBirDate: false,
        date: false,
      },

      setter: {
        name: "",
        idNo: "",
        birDate: "",
        phone: "",
        email: "",
      },

      getter: {
        name: "",
        idNo: "",
        birDate: "",
      },

      agree1: true,
      agree2: true,

      visibleTerms: false,
      visibleState: false,

      date: "",
    };
  },

  mounted() {
    this.date = this.detail.date;

    this.plan = this.$t("info.planSpec") + ["A", "B"][this.detail.plan];

    this.setter = Object.assign({}, this.detail.setter);

    this.getter = Object.assign({}, this.detail.getter);
  },

  methods: {
    copy() {
      this.getter = Object.assign(
        {},
        {
          name: this.setter.name,
          birDate: this.setter.birDate,
          idNo: this.setter.idNo,
        }
      );
    },

    check(field) {
      let rules = {
        setterName: {
          model: this.setter.name,
          ref: this.$refs.setterName,
          message: this.$t("info.verify.setter.name"),
          fn: "require",
        },
        setterId: {
          model: this.setter.idNo,
          ref: this.$refs.setterId,
          message: this.$t("info.verify.setter.idNo"),
          fn: "checkId",
        },
        setterBirDate: {
          model: this.setter.birDate,
          ref: this.$refs.setterBirDate.fpInput(),
          message: this.$t("info.verify.setter.birDate"),
          fn: "checkSetterBirdate",
        },
        setterPhone: {
          model: this.setter.phone,
          ref: this.$refs.setterPhone,
          message: this.$t("info.verify.setter.phone"),
          fn: "checkPhone",
        },
        setterEmail: {
          model: this.setter.email,
          ref: this.$refs.setterEmail,
          message: this.$t("info.verify.setter.email"),
          fn: "checkEmail",
        },
        getterName: {
          model: this.getter.name,
          ref: this.$refs.getterName,
          message: this.$t("info.verify.getter.name"),
          fn: "require",
        },
        getterId: {
          model: this.getter.idNo,
          ref: this.$refs.getterId,
          message: this.$t("info.verify.getter.idNo"),
          fn: "checkId",
        },
        getterBirDate: {
          model: this.getter.birDate,
          ref: this.$refs.getterBirDate.fpInput(),
          message: this.$t("info.verify.getter.birDate"),
          fn: "checkGetterBirdate",
        },
        date: {
          model: this.date,
          ref: this.$refs.date.fpInput(),
          message: this.$t("info.verify.date"),
          fn: "require",
        },
      };

      if (field) {
        let rule = rules[field];
        rules = {};
        rules[field] = rule;
      }

      let result = true;
      for (let key in rules) {
        let rule = rules[key];

        if (!util[rule.fn](rule.model)) {
          this.error[key] = rule.message;

          if (!field && result) {
            rule.ref.focus();
          }

          result = false;
        } else {
          this.error[key] = false;
        }
      }

      return result;
    },

    next() {
      if (!this.check()) {
        return;
      }

      if (!this.agree1) {
        alert(this.$t("info.agree1"));
        return;
      }

      if (!this.agree2) {
        alert(this.$t("info.agree2"));
        return;
      }

      this.$store.commit("detail/setInfo", {
        setter: this.setter,
        getter: this.getter,
      });

      this.$store.commit("detail/setCache");

      this.$router.push("/detail");
    },

    showTerms() {
      this.visibleTerms = true;
    },

    showState() {
      this.visibleState = true;
    },
  },
};
</script>
<style scoped lang="less">
.info {
  .btn-copy {
    padding: 6px 11px;
    color: #007bff;
    border: 1px solid #007bff;
    background: #fff;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:hover {
      color: #fff;
      background: #007bff;
    }
  }
}
</style>