const en = {
    logging: "Logging in...",
    loginError: "Login failed, please try again",

    header: {
        about: "About Us",
        introduction: "Company Introduction",
        partner: "Partner",
        quote: "Insurance Quote",
        claim: "Renovation Insurance",
        domestic: "Domestic Helper Insurance",
        home: "Home Insurance",
        travel: "Travel Insurance",
        owner: "Chilebao",
        expressTravel: "Express Travel Insurance",
        helperTravel: "Express Domestic Helper Insurance",
        bziPartner: "Business Partner",
        konwledge: "Insurance Knowledge",
        contactUs: "Contact Us",
        login: "Login",
        logout: "Logout",
        hello: "Hello!"
    },

    home: {
        title: "Bicycle Accident Insurance",
        desc: "Bicycle accident insurance insures the medical expenses and losses involved in the accident on the day of your cycling.",
        title2: "Scope of Protection",
        title3: "(1) Accidental medical expenses",
        desc3: "Protects the insured's medical, surgical and hospital expenses if he is injured in an accident while riding a bicycle on the date of protection. The insured medical items include Chinese medicine, bone-setting and acupuncture.",
        desc4: "Maximum Coverage: HK$5,000",
        desc5: "Limit of compensation for traditional Chinese medicine, bone-setting and acupuncture:",
        desc6: "-HK$150 each time per day",
        desc7: "-The total protection limit is HK$1,000",
        desc8: "deductible",
        desc9: "The policyholder shall bear the HK$200 deductible fee for each claim",
        title4: "(2) Accidental death and permanent total or partial disability",
        desc10: "The insured shall be protected if unfortunately, death or disability is caused by an accident while riding a bicycle on the date of coverage.",
        desc11: "Maximum protection: HK$400,000",
        title5: "(3) Personal responsibility",
        desc12: "The insured shall be protected from the legal liabilities that the insured person shall bear in the event of a third party's bodily injury or property damage due to his negligence while riding a bicycle on the date of coverage.",
        desc13: "(Except for responsibilities related to any mechanically driven vehicle)",
        title6: "Maximum protection: HK$200,000",
        desc14: "For detailed information and exclusions, please refer to",
        product: "Product Leaflet",
        and: "和",
        trem: "Insurance Clause",
        desc15: "The above information is for reference only and does not form part of the insurance contract. The content, rules and exclusions of this protection plan are detailed in the policy. If there is any discrepancy, the terms and conditions of the policy shall prevail. ",
        select: "Select",
        plan: "Plan",
        planSpec1: "Unexpected Medical Expenses",
        max: "(Highest Guarantee)",
        planSpec2: "Accidental death and permanent total or partial disability",
        planSpec3: "Personal Responsibility",
        money: "Premium",
        moneySpec: "(Premium levy included)",
        planA: "Plan A",
        planB: "Plan B",
        date: "Guarantee Date",
        referer: "referred by the following platform",
        submit: "Insured immediately",
        errorDate: "Please select the guarantee date"
    },

    step: {
        title: "Apply now",
        step1: "Fill in personal information",
        step2: "Confirmation and payment",
        step3: "Finish"
    },

    info: {
        title: "Bicycle Accident Insurance",
        setter: "Insurant Information",
        setterName: "Insured Name",
        hkId: "Hong Kong Identity Card Number",
        hkIdSpec: "It must be the same as the Hong Kong ID card, for example: A123456(7), please enter A1234567.",
        birDate: "Date of Birth",
        mobile: "Phone Number (Mobile)",
        email: "Email Address",
        getter: "Insured Information",
        copy: "Copy from the policyholder's data",
        getterName: "Name of the insured",
        detail: "Guarantee Content",
        date: "Guarantee Date",
        plan: "Guarantee Plan",
        money: "Premium:",
        moneySpec: "(Premium levy included)",
        trem: "Terms and Conditions",
        agree_1: "I/we confirm that I have read and understood your company",
        agree_2: "Terms and Conditions",
        agree_3: "Agreement.",
        agree_4: "Collect personal information",
        state: "Privacy Policy",
        desc1: "Bicycle accident insurance is underwritten by Allied World Assurance Company, Ltd.",
        desc2: "The insurance service is provided by Anbang Insurance Brokers Limited (LPC Insurance Brokers Limited).",
        next: "Next",
        onlyEn: "Only available in English",
        stateShow: "Personal Information Collection Statement",
        planSpec: "Plan",

        verify: {
            setter: {
                name: "Please enter the name of the applicant",
                idNo: "Please enter the correct Hong Kong ID card number",
                birDate: "The insured must be over 18 years old",
                phone: "Please enter the correct phone number",
                email: "Please enter the correct email address"
            },
            getter: {
                name: "Please enter the name of the insured",
                idNo: "Please enter the correct Hong Kong ID card number",
                birDate: "The insured must be between 0-70 years old",
            },
            date: "Please select the guarantee date"
        },

        agree1: "Please confirm that you have read and understood the terms and conditions statement.",
        agree2: "Please confirm that you have read and understood the personal information collection statement."
    },

    detail: {
        paymentMethod: "Select payment method",
        prev: "Previous page",
        confirm: "Confirm and pay"
    }
};

export default en;