const zhHK = {
    logging: "登入中...",
    loginError: "登入失敗，請重試",

    header: {
        about: "關於我們",
        introduction: "公司簡介",
        partner: "合作夥伴",
        quote: "保險報價",
        claim: "裝修保險",
        domestic: "家傭保險",
        home: "家居保險",
        travel: "旅遊保險",
        owner: "置樂保",
        expressTravel: "特快旅遊保",
        helperTravel: "特快家傭保",
        bziPartner: "商業夥伴",
        konwledge: "保險知識",
        contactUs: "聯絡我們",
        login: "登入",
        logout: "登出",
        hello: "您好！"
    },

    home: {
        title: "單車意外保險",
        desc: "單車意外保險保險您騎單車當天因意外而涉及的醫療費用及損失。",
        title2: "保障範圍",
        title3: "(1)意外醫療費用",
        desc3: "保障受保人若不幸再保障日期當天騎單車時因意外導致受傷所需的醫療、手術及住院費用。受保的醫療項目包括中醫、跌打及針灸。",
        desc4: "最高保障額：HK$5,000",
        desc5: "中醫、跌打及針灸賠償限額：",
        desc6: "-每天每次HK$150",
        desc7: "-保障上限合共HK$1,000",
        desc8: "墊底費",
        desc9: "投保人需為每次索償承擔HK$200墊底費",
        title4: "(2)意外死亡及永久完全或部分傷殘",
        desc10: "保障受保人若不幸在保障日期當天騎單車時因意外導致死亡或傷殘。",
        desc11: "最高保障額：HK$400,000",
        title5: "(3)個人責任",
        desc12: "保障受保人在保障日期當天騎單車時疏忽意外致第三者身體受傷或財物損毀而需承擔的法律責任。",
        desc13: "(涉及與任何機械驅動車輛產生之責任除外)",
        title6: "最高保障額：HK$200,000",
        desc14: "有關詳細資料及不保事項，請參閱",
        product: "產品單張",
        and: "及",
        trem: "保單條款",
        desc15: "上述資料只供參考之用，並不構成保險合約的一部分。此項保障計劃的內容細則及不承保事項詳列於保單內，如有任何差異，均以保單之條款細則為準。",
        select: "選擇",
        plan: "計劃",
        planSpec1: "意外醫療費用",
        max: "(最高保障)",
        planSpec2: "意外死亡及永久完全或部分傷殘",
        planSpec3: "個人責任",
        money: "保費",
        moneySpec: "(已包含保費徵費)",
        planA: "計劃A",
        planB: "計劃B",
        date: "保障日期",
        referer: "由以下平臺轉介",
        submit: "立即投保",
        errorDate: "請選擇保障日期"
    },

    step: {
        title: "立即申請",
        step1: "填寫個人資料",
        step2: "確認及付款",
        step3: "完成"
    },

    info: {
        title: "單車意外保險",
        setter: "投保人資料",
        setterName: "投保人姓名",
        hkId: "香港身份証號碼",
        hkIdSpec: "須與香港身份証相同，例：A123456(7)，請輸入 A1234567。",
        birDate: "出生日期",
        mobile: "電話號碼（手提）",
        email: "電郵地址",
        getter: "受保人資料",
        copy: "從投保人資料複製",
        getterName: "受保人姓名",
        detail: "保障內容",
        date: "保障日期",
        plan: "保障計劃",
        money: "保費：",
        moneySpec: "（已包含保費徵費）",
        trem: "條款及細則",
        agree_1: "本人/我們確認已閱讀並理解貴公司的",
        agree_2: "條款和條件",
        agree_3: "聲明。",
        agree_4: "收集個人資料",
        state: "私隱政策",
        desc1: "單車意外保險由世聯保險有限公司(Allied World Assurance Company, Ltd)承保。",
        desc2: "投保服務由安邦保險經紀有限公司(LPC Insurance Brokers Limited)提供。",
        next: "下一步",
        onlyEn: "只提供英文版本",
        stateShow: "收集個人資料聲明",
        planSpec: "計劃",

        verify: {
            setter: {
                name: "請輸入投保人姓名",
                idNo: "請輸入正確的香港身份証號碼",
                birDate: "投保人必須大於18周歲",
                phone: "請輸入正確的電話號碼",
                email: "請輸入正確的電郵地址"
            },
            getter: {
                name: "請輸入受保人姓名",
                idNo: "請輸入正確的香港身份証號碼",
                birDate: "受保人必須在0-70周歲之間",
            },
            date: "請選擇保障日期"
        },

        agree1: "請先確認已閱讀並理解條款和條件聲明。",
        agree2: "請先確認已閱讀並理解收集個人資料聲明。"
    },

    detail: {
        paymentMethod: "選擇付款方式",
        prev: "上一頁",
        confirm: "確認及付款"
    }
};

export default zhHK;