import { decode } from "js-base64";

export default {
    state: {
        login: false,
        username: null,
        info: {}
    },

    mutations: {
        ["user/setInfo"](state, info) {
            state.login = true;
            state.username = info.user_preferred_username;
            state.info = Object.assign({}, info);
        }
    },

    actions: {
        ["user/getInfo"]({ commit }) {
            const token = window.localStorage.getItem("token");
            if (!token) {
                return false;
            }

            const result = token.split(".");
            const payload = JSON.parse(decode(result[1]));

            if (!payload.exp || (new Date()).getTime() / 1000 >= payload.exp) {
                return false;
            }

            commit("user/setInfo", payload);
        }
    }
};
