import { createRouter, createWebHistory } from "vue-router";

import Home from "./pages/Home";
import Info from "./pages/Info";
import Detail from "./pages/Detail";
import Login from "./pages/Login";
import Success from "./pages/Success";

const routes = [
    {
        path: "/",
        component: Home
    },
    {
        path: "/info",
        component: Info
    },
    {
        path: "/detail",
        component: Detail
    },
    {
        path: "/payment/success/:id",
        component: Success
    },
    {
        path: "/login",
        component: Login
    }
];

const VueRouter = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

export default VueRouter;