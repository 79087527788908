<template>
  <footer id="footer">
    <div class="footer-left">
      <span>© 2019 版權所有</span>
      <span><a>網站地圖</a></span>
      <span><a>條款及細則</a></span>
      <span><a>隱私政策</a></span>
    </div>

    <div class="footer-right">
      <span>保險業監管局(IA)認可持牌保險經紀公司:</span>
      <span>
        <img alt="" src="../../assets/images/logo-sm.png" />
        安邦保險經紀有限公司 (牌照號碼: FB1496)
      </span>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
#footer {
  width: 100%;
  padding: 0 45px;
  background: #fcb92f;
  height: 53px;
  line-height: 53px;
  color: #fff;
  position: absolute;
  bottom: 0;

  span {
    margin-right: 20px;
    display: inline-block;

    &:last-child {
      margin: 0;
    }
  }

  .footer-left {
    float: left;
  }

  .footer-right {
    float: right;

    img {
      position: relative;
      height: 14px;
      top: 3px;
    }
  }

  @media (max-width: 1140px) {
    padding: 5px 20px;
    height: auto;
    line-height: 35px;

    .footer-left,
    .footer-right {
      display: block;
      float: none;
    }

    .footer-right {
      span {
        display: block;
      }
    }
  }
}
</style>