export default {
    state: {
        data: {
            plan: 0,
            setter: {
                name: "",
                idNo: "",
                birDate: "",
                phone: "",
                email: ""
            },
            getter: {
                name: "",
                idNo: "",
                birDate: ""
            },
            referer: "",
            date: ""
        }
    },

    mutations: {
        ["detail/setData"](state, data) {
            state.data = Object.assign({}, data);
        },

        ["detail/setReferer"](state, referer) {
            state.data.referer = referer ? referer : "";
        },

        ["detail/setDate"](state, date) {
            state.data.date = date;
        },

        ["detail/setPlan"](state, plan) {
            if (plan instanceof Array) {
                plan = plan.shift();
            }

            state.data.plan = plan;
        },

        ["detail/setInfo"](state, info) {
            state.data.setter = Object.assign({}, info.setter);
            state.data.getter = Object.assign({}, info.getter);
        },

        ["detail/setCache"](state) {
            window.localStorage.setItem("detail", JSON.stringify(state.data));
        }
    },

    actions: {
        ["detail/getCache"]({ commit }) {
            const cache = window.localStorage.getItem("detail");
            if (!cache) {
                return false;
            }

            const data = JSON.parse(cache);
            commit("detail/setData", data);
        },

        ["detail/clearCache"]() {
            window.localStorage.removeItem("detail");
        }
    }
};
