<template>
  <div class="home page container">
    <template v-if="status">
      <div class="box flex">
        <div class="info">
          <div class="page-title group">{{ $t("home.title") }}</div>
          <div class="group">{{ $t("home.desc") }}</div>
          <div class="group sub-title">{{ $t("home.title2") }}</div>
          <div class="group">
            <div class="group-title">{{ $t("home.title3") }}</div>
            <div>{{ $t("home.desc3") }}</div>
          </div>
          <div class="group">{{ $t("home.desc4") }}</div>
          <div class="group">
            <div>{{ $t("home.desc5") }}</div>
            <div>{{ $t("home.desc6") }}</div>
            <div>{{ $t("home.desc7") }}</div>
          </div>
          <div class="group">
            <div>{{ $t("home.desc8") }}</div>
            <div>{{ $t("home.desc9") }}</div>
          </div>
          <div class="group">
            <div class="group-title">{{ $t("home.title4") }}</div>
            <div>{{ $t("home.desc10") }}</div>
          </div>
          <div class="group">{{ $t("home.desc11") }}</div>
          <div class="group">
            <div class="group-title">{{ $t("home.title5") }}</div>
            <div>{{ $t("home.desc12") }}</div>
            <div>{{ $t("home.desc13") }}</div>
          </div>
          <div class="group">{{ $t("home.title6") }}</div>
          <div class="desc">
            <div>
              {{ $t("home.desc14") }}
              <a href="/file/Cycling Leaflet.pdf" target="_blank">
                {{ $t("home.product") }}
              </a>
              {{ $t("home.and") }}
              <a
                href="/file/CPG Jacket Wording SP-CP0216PJ.pdf"
                target="_blank"
              >
                {{ $t("home.trem") }}
              </a>
            </div>
            <div>{{ $t("home.desc15") }}</div>
          </div>
        </div>
        <div class="image">
          <img src="@/assets/images/detail.png" />
        </div>
      </div>

      <div class="box">
        <div class="table">
          <table class="plan">
            <thead>
              <tr>
                <th>{{ $t("home.select") }}</th>
                <th>{{ $t("home.plan") }}</th>
                <th>
                  <div>{{ $t("home.planSpec1") }}</div>
                  <div>{{ $t("home.max") }}</div>
                </th>
                <th>
                  <div>{{ $t("home.planSpec2") }}</div>
                  <div>{{ $t("home.max") }}</div>
                </th>
                <th>
                  <div>{{ $t("home.planSpec3") }}</div>
                  <div>{{ $t("home.max") }}</div>
                </th>
                <th>
                  <div>{{ $t("home.money") }}</div>
                  <div>{{ $t("home.moneySpec") }}</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr @click="selectPlan('0')">
                <td>
                  <input
                    type="checkbox"
                    v-model="plan"
                    value="0"
                    @change="selectPlan('0')"
                  />
                </td>
                <td>{{ $t("home.planA") }}</td>
                <td>$2,000</td>
                <td>$100,000</td>
                <td>$100,000</td>
                <td>$15</td>
              </tr>
              <tr @click="selectPlan('1')">
                <td>
                  <input
                    type="checkbox"
                    v-model="plan"
                    value="1"
                    @change="selectPlan('1')"
                  />
                </td>
                <td>{{ $t("home.planB") }}</td>
                <td>$5,000</td>
                <td>$400,000</td>
                <td>$200,000</td>
                <td>$28</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="form-group">
          <div class="form-item">
            <label class="form-field">{{ $t("home.date") }}(DD/MM/YYYY)</label>
            <flat-pickr
              v-model="date"
              :config="config"
              ref="date"
              class="form-input"
            ></flat-pickr>
            <template v-if="referer">
              <label class="form-field">{{ $t("home.referer") }}</label>
              <input class="form-input" readonly v-model="referer" />
            </template>
          </div>
        </div>
        <div class="action">
          <button class="btn" @click="next">{{ $t("home.submit") }}</button>
        </div>
      </div>
    </template>
    <template v-else>
      Only query string in listed referers are allow to access this product
    </template>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import { MandarinTraditional } from "flatpickr/dist/l10n/zh-tw";
import { Mandarin } from "flatpickr/dist/l10n/zh";

import { mapState } from "vuex";

export default {
  name: "Home",

  components: {
    flatPickr,
  },

  computed: {
    ...mapState({
      detail: (state) => state.detail.data,
    }),
  },

  data() {
    return {
      status: true,

      date: null,

      referer: null,

      plan: [0],

      config: {
        disable: ["1900-01-01"],
        wrap: true,
        altInput: true,
        altFormat: "d/m/Y",
        locale: localStorage.lang == "zhHK" ? MandarinTraditional : Mandarin,
        minDate: "today",
      },
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.getReferer();

      this.date = this.detail.date;
      this.plan = [this.detail.plan];
    },

    getReferer() {
      const allow = process.env.VUE_APP_REFERER_ALLOW.split(",");
      const referer = this.$route.query.referer;

      if (referer && !allow.includes(referer)) {
        this.status = false;
        return;
      }

      this.referer = referer;
    },

    next() {
      if (!this.date) {
        alert(this.$t("home.errorDate"));
        this.$refs.date.fpInput().focus();
        return;
      }

      this.$store.commit("detail/setDate", this.date);
      this.$store.commit("detail/setPlan", this.plan);
      this.$store.commit("detail/setReferer", this.referer);

      this.$store.commit("detail/setCache");

      this.$router.push("/info");
    },

    selectPlan(plan) {
      this.plan = [plan];
    },
  },
};
</script>
<style scoped lang="less">
.home {
  .flex {
    display: flex;

    .info {
      width: 60%;
    }

    .image {
      width: 40%;

      img {
        width: 100%;
      }
    }
  }

  .form-field {
    font-size: 14px;
  }

  .action {
    margin-top: 15px;

    .btn {
      width: 94px;
      height: 34px;
      line-height: 18px;
      border-radius: 5px;
    }
  }

  .table {
    max-width: 100%;
    overflow: auto;
  }

  .plan {
    margin: 0 0 30px 0px;
    min-width: 665px;
    text-align: center;
    border-collapse: collapse;

    th {
      max-width: 110px;
      font-weight: 400;
      border: 1px solid #e2e2e2;
      padding: 8px 20px;
      background: #f9fafb;
    }

    td {
      border: 1px solid #e2e2e2;
      padding: 8px 20px;
    }
  }

  /deep/.form-input {
    width: 230px !important;
  }

  @media (max-width: 990px) {
    .info {
      width: 100% !important;
    }

    .image {
      display: none;
    }
  }
}
</style>