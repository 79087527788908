let util = {

    dateFormat(date) {
        return date.split("-").reverse().join("/");
    },

    require(value) {
        return "" != value.trim();
    },

    checkEmail(email) {
        const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
        return reg.test(email);
    },

    checkId(id) {
        const reg = /^[A-Z]{1,2}[0-9]{6}[0-9A]{1}$/;
        if (!reg.test(id)) {
            return false;
        }

        const checkin = {
            " ": 36, A: 10, B: 11, C: 12, D: 13, E: 14, F: 15, G: 16, H: 17, I: 18, J: 19, K: 20, L: 21, M: 22, N: 23, O: 24, P: 25, Q: 26, R: 27, S: 28, T: 29, U: 30, V: 31, W: 32, X: 33, Y: 34, Z: 35
        };

        if (id.length == 8) {
            id = " " + id;
        }

        let sum = 0;
        for (let index = 0; index < 8; index++) {
            // 数字直接计算
            if (!checkin[id[index]]) {
                sum += (id[index] * (9 - index));
            } else {
                sum += (checkin[id[index]] * (9 - index));
            }
        }

        const check = sum % 11;
        const checkout = id[8];

        if (check === 0) {
            return checkout == 0;
        }

        if (check === 1) {
            return checkout == 'A';
        }

        return checkout == 11 - check;
    },

    checkPhone(phone) {
        const reg = /^[23569]{1}[0-9]{7}$/;
        return reg.test(phone);
    },

    checkSetterBirdate(date) {
        return 18 <= this.getAge(date);
    },

    checkGetterBirdate(date) {
        return 0 <= this.getAge(date) && 70 >= this.getAge(date);
    },

    getAge(strBirthday) {
        var returnAge,
            strBirthdayArr = strBirthday.split("-"),
            birthYear = strBirthdayArr[0],
            birthMonth = strBirthdayArr[1],
            birthDay = strBirthdayArr[2],
            d = new Date(),
            nowYear = d.getFullYear(),
            nowMonth = d.getMonth() + 1,
            nowDay = d.getDate();

        if (nowYear == birthYear) {
            returnAge = 0;
        }

        else {
            var ageDiff = nowYear - birthYear;
            if (ageDiff > 0) {
                if (nowMonth == birthMonth) {
                    var dayDiff = nowDay - birthDay;
                    if (dayDiff < 0) {
                        returnAge = ageDiff - 1;
                    } else {
                        returnAge = ageDiff;
                    }
                } else {
                    var monthDiff = nowMonth - birthMonth;
                    if (monthDiff < 0) {
                        returnAge = ageDiff - 1;
                    }
                    else {
                        returnAge = ageDiff;
                    }
                }
            } else {
                returnAge = -1;
            }
        }

        return returnAge;
    }

};

export default util;