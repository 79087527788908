<template>
  <div class="page container">
    <div class="box">{{ $t("logging") }}</div>
  </div>
</template>
<script>
export default {
  name: "Login",

  components: {},

  mounted() {
    this.init();
  },

  data() {
    return {
      code: null,
    };
  },

  methods: {
    init() {
      if (!this.$route.query) {
        alert(this.$t("loginError"));
        this.$router.push("/");
        return false;
      }

      this.code = this.$route.query.code;

      this.login();
    },

    async login() {
      const result = await this.$fetch("session", {
        method: "POST",
        body: {
          code: this.code,
        },
      });

      if (!result.isOk) {
        alert(result.data.message);
        return;
      }

      window.localStorage.setItem("token", result.data.data.token);

      this.$store.dispatch("user/getInfo");
      this.$router.push("/");
    },
  },
};
</script>